





























































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { TaskController } from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    FlexTooltip,
  },
})
export default class AssignmentDetail extends Vue {
  private assignmentInfo: any = {}
  private assignmentId: any
  private detailModalShow: boolean = false
  private loading: boolean = false
  private studentData: any = []
  private moment = moment
  private descFold = false
  private descFoldShow = false

  private get infoList(): any {
    return [
      this.type === 'subjectClass'
        ? {
            label: this.$t('common.subject'),
            value: this.assignmentInfo.subjectName,
          }
        : -1,
      {
        label: this.$t(`common.${this.type}`),
        value: this.assignmentInfo.courseName,
      },
      {
        label: this.$t('common.type'),
        value: this.assignmentInfo.typeName,
      },
      {
        label: this.$t('common.deadline'),
        value: this.assignmentInfo.endDate
          ? moment(this.assignmentInfo.endDate).format('YYYY.MM.DD HH:mm')
          : '',
      },
      {
        label: this.$t('assignment.scoreAllow'),
        value: this.$t(`assignment.${this.assignmentInfo.scoreFlag ? 'allow' : 'disallow'}`),
      },
      this.assignmentInfo.scoreFlag
        ? [
            {
              label: this.$t('assignment.topScore'),
              value: this.assignmentInfo.topScore || 0,
            },
            {
              label: this.$t('assignment.totalIncluded'),
              value: this.$t(`common.${this.assignmentInfo.inTotal ? true : false}`),
            },
          ]
        : -1,
      {
        label: this.$t('assignment.online'),
        value: this.$t(`common.${this.assignmentInfo.online ? true : false}`),
      },
      this.assignmentInfo.online
        ? {
            label: this.$t('assignment.handInAfterDead'),
            value: this.$t(`assignment.${this.assignmentInfo.overDeadline ? 'allow' : 'disallow'}`),
          }
        : -1,
    ]
      .filter(item => item !== -1)
      .flat(1)
  }

  private get type(): any {
    return this.$route.query.assignType
  }

  private get studentId(): number {
    return this.$store.state.currentStudent?.studentId
  }

  private getAssignmentInfo(): void {
    TaskController.detail(this.assignmentId, this.studentId)
      .then(res => {
        this.assignmentInfo = res.data || {}
        this.calcExpand()
      })
      .catch(err => console.log(err))
  }

  private calcExpand(): void {
    this.$nextTick(() => {
      if (this.descFoldShow) return
      this.descFoldShow = false
      let el = document.getElementById(`aDescContent`)
      if (!el) return
      let ch = el.clientHeight
      let sh = el.scrollHeight
      this.descFoldShow = sh > ch
    })
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    this.assignmentId = to.params.assignId
    this.getAssignmentInfo()
  }
}
